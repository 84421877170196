interface Rgb {
  r: number;
  g: number;
  b: number;
}
export const hexToRgb = (hex: string): Rgb | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const rgbToPercent = (rgb: Rgb) => {
  const percent = {
    r: rgb.r / 255,
    g: rgb.g / 255,
    b: rgb.b / 255
  };
  return [percent.r, percent.g, percent.b];
};

export const hexToPercent = (hex: string) => {
  return rgbToPercent(hexToRgb(hex) as Rgb);
};

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export const rgbToHex = (rgb: Rgb) => {
  return (
    "#" + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b)
  );
};

export const percentToRgb = (percentages: Array<number>): Rgb => {
  return {
    r: percentages[0] * 255,
    g: percentages[1] * 255,
    b: percentages[2] * 255
  };
};

export const percentToHex = (percentages: Array<number>): string => {
  return rgbToHex(percentToRgb(percentages));
};

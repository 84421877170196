import lottie from 'lottie-web';
import { v4 as uuid } from 'uuid';
import { percentToHex } from '@/helpers/colors';
import store from '@/store';
import axios from 'axios';
import Sentry from '@sentry/vue';
import { Template, TemplateVariant } from '@/types/template';
import WebFont from 'webfontloader';
export const loadFonts = async (font: any, data: any) => {
  const families = Array.isArray(font.google) ? [...font.google] : [font.google];
  if (!families || families.length <= 0) return;
  WebFont.load({
    google: { families },
    active: () => {}
  });
};

export const loadFields = (template: Template, templateVariant: TemplateVariant) => {
  return template.fields.map((f: any) => {
    templateVariant.json_data.layers.forEach((layer: any) => {
      if (Array.isArray(f.selector) && f.selector.includes(layer.nm)) {
        f.target = f.selector.map((s: any) =>
          templateVariant.json_data.layers.find((l: any) => l.nm === s)
        );
      } else if (layer.nm === f.selector) {
        f.target = layer;
      }
    });

    if (f.type === 'font') {
      loadFonts(f.params, templateVariant.json_data);
      if (Array.isArray(f.target)) {
        f.target.forEach((t: any) => {
          if (f.params.fName) t.t.d.k[0].s.f = f.params.fName;
        });
      } else {
        if (f.params.fName) f.target.t.d.k[0].s.f = f.params.fName;
      }
    }

    if (f.type === 'text' || f.type === 'textarea') {
      if (Array.isArray(f.target)) {
        f.target.forEach((t: any) => {
          if (f.params.text) t.t.d.k[0].s.t = f.params.text;
          if (f.params.color) t.t.d.k[0].s.fc = f.params.color;
        });
      } else {
        if (f.params.text) f.target.t.d.k[0].s.t = f.params.text;
        if (f.params.color) f.target.t.d.k[0].s.fc = f.params.color;
      }
    }

    if (f.type === 'color') {
      f.params._hex = percentToHex(f.params.value);
      if (Array.isArray(f.target)) {
        f.target.forEach((t: any) => {
          if (f.params.value) {
            if (t.t) t.t.d.k[0].s.fc = f.params.value;
            if (t.sc) t.sc = f.params._hex;
            if (t.shapes) t.shapes[0].it[1].c.k = f.params.value;
          }
        });
      } else if (f.params.value) {
        if (f.target.t) f.target.t.d.k[0].s.fc = f.params.value;
        if (f.target.sc) f.target.sc = f.params._hex;
        if (f.target.shapes) f.target.shapes[0].it[1].c.k = f.params.value;
      }
    }

    if (f.type === 'image') {
      // If type IMAGE => MAKE the asset available
      f.asset = templateVariant.json_data.assets.find((a: any) => a.id === f.target.refId);
      if (f.params && f.params.default) {
        f.asset.u = '';
        f.asset.p = f.params.default;
      }
    }
    return f;
  });
};

export const dataURLtoFile = (dataUrl: any, filename: any) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const handleCroppedImage = async (
  img: string,
  index: number,
  callback: Function = () => {}
) => {
  const { assets } = store.state.template!.json_data;
  const fields = store.state.templateFields;
  const field = { ...fields[index] };
  const indexAsset = assets.findIndex((f: any) => f.id === field.asset.id);
  const indexField = fields.findIndex((f: any) => f.selector === field.selector);

  const data = new FormData();
  data.append('title', uuid());
  data.append('file', dataURLtoFile(img, uuid()));
  data.append(
    'tags',
    process.env.VUE_APP_BUILD ? JSON.stringify([process.env.VUE_APP_BUILD]) : JSON.stringify([])
  );

  // To be placed in the .env
  const url = 'https://api.staging.vx.glooh.app';
  try {
    const upload = (await axios.post(`${url}/files`, data)).data;
    await axios.patch(`${url}/files/${upload.data.id}`, {
      tags: process.env.VUE_APP_BUILD ? [process.env.VUE_APP_BUILD] : []
    });
    const uploadedImgURL = `${url}/assets/${upload.data.id}`;
    field.asset.p = uploadedImgURL;
    field.params = { ...field.params, default: uploadedImgURL };

    assets[indexAsset] = { ...field.asset, p: uploadedImgURL };
    fields[indexField].target.p = uploadedImgURL;
    fields[indexField].params = { ...field.params, default: uploadedImgURL };
    store.commit('setTemplate', {
      ...store.state.template,
      assets,
      fields
    });
  } catch (err) {
    Sentry.captureException('Failed to upload file on Directus', err);
  }
  if (typeof callback === 'function') callback();
};
